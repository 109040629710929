<template>
<div id="">
  <v-autocomplete
    v-model="selectedCountry"
    :items="countriesISO"
    :label="$t('tenants.nationality')"
    :error-messages="errorMessage"
    :hint="hintText"
    prepend-icon="location_city"
  >
  </v-autocomplete>


</div>
</template>
<script>
import countries from "i18n-iso-countries"


export default {
  name: "",
  props: ['nationality', 'labelText', 'hintText', 'errorMessage'],
  data: () => ({
    error:false,
    selectedCountry: "",

  }),
  computed:{
    countriesISO: function(){
      countries.registerLocale(require("i18n-iso-countries/langs/"+this.$i18n.locale+".json"));
      // console.log('locale:' ,this.$i18n.locale);
      // console.log(countries.getNames('en'), "countries")
      let tObj=countries.getNames(this.$i18n.locale)
      let tArr=[]
      for (var c in tObj) {
        if (tObj.hasOwnProperty(c)) {
          let element={text:tObj[c],value:c}
          tArr.push(element)
        }

      }
      // console.log(tArr)
      return tArr


    }
  },
  watch: {
    selectedCountry: function() {
      this.$emit('changeNationality', this.selectedCountry)
    },
    errorMessage: function(){
      if (this.errorMessage!=''){
        this.error=true
      } else {
        this.error=false
      }
    }
  },
  created() {
    //do something after creating vue instance
    this.selectedCountry = this.nationality
  },
}
</script>
<style lang="scss" scoped>
.error {
  color:red !important;
}
.error label {
    color: red !important;
}

.error label
.error MdInput {
    border: 1px solid red;
    background-color: #ffc9aa;
}

</style>
