<template>
<div id=""><v-layout justify-center>
    <v-dialog v-model="showDialog" persistent max-width="500px">
      <v-btn slot="activator" v-if="noButton != true" color="primary" dark>{{$t('newListing.addTenant')}}</v-btn>
      <v-card>
        <v-card-title>
          <span class="headline">{{$t('uploads.newTenant')}}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12 sm6>
                <v-menu
                 ref="menu1"
                 :close-on-content-click="false"
                 v-model="menu1"
                 :nudge-right="40"
                 :return-value.sync="newTenant.moveInDate"
                 lazy
                 transition="scale-transition"
                 offset-y
                 full-width
                 min-width="290px"
               >
                 <v-text-field
                   slot="activator"
                   v-model="$v.newTenant.moveInDate.$model"
                   :label="$t('userRequest.mid')+'*'"
                   prepend-icon="flight_land"
                   readonly
                   clearable
                   :error-messages="moveInDateErrors"
                   @blur="$v.newTenant.moveInDate.$touch()"
                 ></v-text-field>
                 <v-date-picker :locale="$vuetify.lang.current" v-model="newTenant.moveInDate" @input="$refs.menu1.save(newTenant.moveInDate)"></v-date-picker>
               </v-menu>
              </v-flex>
              <v-flex xs12 sm6>
                <v-menu
                 ref="menu2"
                 :close-on-content-click="false"
                 v-model="menu2"
                 :nudge-right="40"
                 :return-value.sync="newTenant.moveOutDate"
                 lazy
                 transition="scale-transition"
                 offset-y
                 full-width
                 min-width="290px"
               >
                 <v-text-field
                   slot="activator"
                   :error-messages="moveOutDateErrors"
                   v-model="newTenant.moveOutDate"
                   :label="$t('userRequest.mod')+'*'"
                   prepend-icon="flight_takeoff"
                   readonly
                   clearable
                   @blur="$v.newTenant.moveOutDate.$touch()"
                 ></v-text-field>
                 <v-date-picker :locale="$vuetify.lang.current" v-model="newTenant.moveOutDate" @input="$refs.menu2.save(newTenant.moveOutDate)"></v-date-picker>
               </v-menu>
              </v-flex>
              <v-flex xs12>
                <NationalityDropdown :nationality="newTenant.nationality" @changeNationality="newTenant.nationality=$event" labelText="Nationality" hintText="Please select your country"></NationalityDropdown>
              </v-flex>
              <v-flex xs12>
                <v-select
                  :items="genders"
                  v-model="newTenant.gender"
                  :error-messages="genderErrors"
                  :label="$t('profile.gender')+'*'"
                  prepend-icon="people"
                  @blur="$v.newTenant.gender.$touch()"
                ></v-select>
              </v-flex>
              <v-flex xs12>
                <v-select
                  :items="occupationOptions"
                  v-model="newTenant.occupation"
                  :label="$t('profile.occupation')"
                  prepend-icon="work"
                ></v-select>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  :label="$t('profile.school')"
                  prepend-icon="domain"
                  v-model="newTenant.school"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" flat @click.native="showDialog = false">{{$t('uploads.close')}}</v-btn>
          <v-btn color="primary" flat @click.native="addTenant">{{$t('profile.save')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</div>
</template>
<script>
import moment from 'moment'
import NationalityDropdown from '../shared/NationalityDropdown.vue'
import {required} from 'vuelidate/lib/validators'
export default {
  name: "",
  props: {
    myDialog:Boolean,
    moveOutDate:String,
    noButton:{
      type:Boolean,
      default:false
    },
    mid:{
      type:String,
      default:""
    }
  },
  data: () => ({
    menu1:false,
    menu2:false,
    dialogError: "",
    showSnackbar: false,
    showDialog: false,
    newTenant: {
      gender: "",
      nationality: "",
      occupation: "",
      moveInDate: "",
      moveOutDate: "",
      school: "",
    },
  }),
  validations: {
    newTenant:{
      moveInDate:{
        required
      },
      moveOutDate:{
        required
      },
      gender:{
        required
      }
    }
  },
  methods: {
    addTenant() {
      // upload the tenant to tenants, not to room as it doesnt exist so return the ID to privateRoom and push it to the array
      // console.log('postTheTenant')
      this.$v.newTenant.$touch()
      if (this.$v.newTenant.$error || this.$v.newTenant.$invalid) {
        return
      } else {
        let toEmit = {
          tenant: this.newTenant,
        }
        this.$emit('addNewTenant', toEmit)
        this.showDialog = false
        this.showSnackbar = true
        // console.log('Tenant added')
        this.newTenant={}
      }

    },

  },
  computed: {
    genders:function() {
        let t=[{
            text: 'Male',
            value: "m"
          },
          {
            text: 'Female',
            value: "f"
          }
        ]
        if(this!=undefined){
          t=[{
              text: this.$t("profile.male"),
              value: "m"
            },
            {
              text: this.$t("profile.female"),
              value: "f"
            }
          ]
        }
        return t
    },
    occupationOptions:function(){
      let t=[{
        text: 'Student',
        value: "student"
      }, {
        text: 'Worker',
        value: 'worker'
      }]
      if (this!=undefined){
        t=[
          {
            text: this.$t('otherTenants.student'),
            value: "student"
          }, {
            text: this.$t('otherTenants.worker'),
            value: 'worker'
          }
        ]
      }
      return t
    },
    moveInDateErrors() {
      const errors=[]
      if (!this.$v.newTenant.moveInDate.$dirty) return errors
      !this.$v.newTenant.moveInDate.required && errors.push(this.$t('userRequest.midError'))
      return errors
    },
    moveOutDateErrors() {
      const errors=[]
      if (!this.$v.newTenant.moveOutDate.$dirty) return errors
      !this.$v.newTenant.moveOutDate.required && errors.push(this.$t('userRequest.modError'))
      return errors
    },
    genderErrors() {
      const errors=[]
      if (!this.$v.newTenant.gender.$dirty) return errors
      !this.$v.newTenant.gender.required && errors.push(this.$t('userRequest.genderError'))
      return errors
    }
  },
  watch: {
    myDialog: function() {
      this.showDialog = this.myDialog
    },
    showDialog: function() {
      if(!this.showDialog){
        this.$emit('cancel', this.showDialog)
      }
    },
    moveOutDate: function() {
      this.newTenant.moveOutDate = this.moveOutDate
    },
    mid: function(){
      this.newTenant.moveInDate = moment(this.mid).format("YYYY-MM-DD")
    }
  },
  created() {
    //do something after creating vue instance
  },
  components: {
    NationalityDropdown,
  }

}
</script>
<style lang="scss" scoped>
.md-dialog {
    max-width: 768px;
    width: 80%;
}
</style>
