<template>
  <v-flex xs12>

  <v-card class="studioCardContainer">
    <v-container grid-list-xs fluid>
      <v-layout  wrap>
        <v-flex xs12 md4>
          <v-img
          :src="thumbnail"
          height="200px"
          >
          </v-img>
        </v-flex>
        <v-flex xs12 md8>
          <v-card-title primary-title v-if="studio.general.hasOwnProperty('name')">
            {{studio.general.name}}
          </v-card-title>
          <v-card-title primary-title v-else>
            {{$t('landlordDashboard.noTitle')}}
          </v-card-title>
          <v-card-text>

            <RoomInfo :type="'Studio'" :studioId="studioId" :apartmentId="studioId">
            </RoomInfo>
          </v-card-text>

        </v-flex>
        <v-card-actions>
            <v-btn color="primary" flat :href="'/studio?studioId='+studioId">{{$t('landlordDashboard.manageStudio')}}</v-btn>
            <v-btn color="#ff4000" dark v-if="user.roleValue>50"  flat @click="duplicateStudio()" >duplicate</v-btn>
            <v-btn color="error" @click="del">Remove</v-btn>
        </v-card-actions>
      </v-layout>
    </v-container>
  </v-card>
</v-flex>
</template>
<script>
import axios from 'axios'
import RoomInfo from './RoomInfo.vue'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'


export default {
  name: "",
  props: ['studioId'],
  data: () => ({
    thumbnail:"https://res.cloudinary.com/my-room-abroad/image/upload/w_600/Logo/mra-no-photos-placeholder.jpg",
    studio: {
      general:{}
    },
  }),
  methods: {
    del(){
      console.log('users/'+this.studio.general.landlordId+'.json');
      axios.get('users/'+this.studio.general.landlordId+'.json')
      .then(res=>{
        let ll=res.data
        for (var id in ll.studios) {
          if (ll.studios.hasOwnProperty(id)) {
            if (ll.studios[id].studioId==this.studioId){
              console.log('users/'+this.studio.general.landlordId+'/studios/'+id+'.json');
              axios.delete('users/'+this.studio.general.landlordId+'/studios/'+id+'.json?auth='+this.$store.state.auth.idToken)
              axios.patch('studios/'+this.studioId+'.json?auth='+this.$store.state.auth.idToken,{draft:true})
            }
          }
        }

      })
    },
    duplicateStudio(){
      alert('flat is being duplicated.')
      console.log('duplicate this flat', this.studio);
      let tFlat=JSON.parse(JSON.stringify(this.studio))
      tFlat.general.location=null
      tFlat.draft=true
      tFlat.general.title=null
      tFlat.general.dateAdded=new Date()
      tFlat.general.name=tFlat.general.name +" - Duplicate"
      tFlat.general.fullRoomNumber=null
      tFlat.general.complete=false
      tFlat.visual=null
      tFlat.tenants=null
      tFlat.visit=0
      tFlat.billing=null
      axios.post('/studios.json' + '?auth=' + this.$store.state.auth.idToken, tFlat)
      .then((res) => {
        let studioId = res.data.name
        axios.post('/users/'+tFlat.general.landlordId+'/studios.json' + '?auth=' + this.$store.state.auth.idToken, {
          'studioId':studioId
        })
        .catch(err=>{
          console.log(err);
        })
      })
      .catch(err=>{
        console.log(err);
      })
    },

  },
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  created() {
    //do something after creating vue instance
    let vThis=this
    axios.get('studios/' + vThis.studioId + '.json')
      .then(res => {
        // console.log('apt summary found')
        vThis.studio = res.data
        // console.log('LOADED Studio:!!!',vThis.studio)
        if(vThis.studio.hasOwnProperty('visual')) {
          if (vThis.studio.visual.photos.hasOwnProperty('0')){
          // console.log('set new thumbnail')
          vThis.thumbnail='https://res.cloudinary.com/my-room-abroad/image/fetch/h_200,q_auto,fl_lossy,f_auto/'+ vThis.studio.visual.photos[0].url
          }
        }
        // console.log('count remaining rooms')
        // vThis.countRemainingRooms()
      })
      .catch(err => {
        // console.log('apartments not found')
        console.log(err)
      })
  },
  components: {
    RoomInfo
  }
}
</script>
<style lang="scss" scoped>
.carousel {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    // width: 100%;
    height: 180px;
    overflow: hidden;
}
.card {
    margin: 5%;
    width: 90%;
}

.carouselImg {
    object-position: center;
    object-fit:cover;
    max-height: 180px;
}
.card-img-container {

    width: 100%;
    height: 180px;
    display: block;
    position: relative;
    overflow: hidden;
}
.card-img-container img {
    margin: auto;
    max-width: 100%;
    max-height: 180px;
    width: auto;
}

</style>
