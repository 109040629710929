<template>
  <v-flex xs12>

  <v-card class="apartmentCardContainer">
    <v-container grid-list-xs fluid>
      <v-layout  wrap>
        <v-flex xs12 md4>
          <v-img
          :src="thumbnail"
          height="200px"
          :aspect-ratio="1.77"
          :href="'/newApartment?apartmentId='+ apartmentId"
          >
          </v-img>
        </v-flex>
        <v-flex xs12 md8>
          <v-card-title primary-title v-if="this.apartment!=null && apartment.general.hasOwnProperty('name')">
            {{apartment.general.name}} - {{$t("newListing.roomContracts")}}
          </v-card-title>
          <v-card-title primary-title v-else>
            {{$t('landlordDashboard.noTitle')}} - {{$t("newListing.roomContracts")}}
          </v-card-title>
          <v-card-text>
              <RoomInfo v-if="orderedRooms!=[]" v-for="(room, index) in orderedRooms" :type="'Room'" :key="index" :roomId="room.id" :apartmentId="apartmentId" :remainingRooms="remainingRooms">
              </RoomInfo>
          </v-card-text>
        </v-flex>
        <v-card-actions>
            <v-btn color="primary" flat   :href="'/newApartment?apartmentId='+ apartmentId">{{$t('landlordDashboard.manageApt')}}</v-btn>
            <v-btn color="primary" :disabled="apartment.draft" :href="'/room/'+apartmentId" v-if="remainingRooms>0">{{$t('landlordDashboard.addRoom')}}</v-btn>
            <v-btn color="primary" :disabled="apartment.draft" :href="'/apartmentDetails/'+apartmentId+'/rooms'" >{{$t('landlordDashboard.launch')}}</v-btn>
            <v-btn color="#ff4000" dark v-if="user.roleValue>50"  flat @click="duplicateFlat()" >duplicate</v-btn>
            <v-btn color="error" @click="del">Remove</v-btn>
        </v-card-actions>
      </v-layout>
    </v-container>
  </v-card>
</v-flex>

</template>
<script>
import axios from 'axios'
import Draggable from 'vuedraggable'
import RoomInfo from './RoomInfo.vue'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'


export default {
  name: "",
  props: ['apartmentId'],
  data: () => ({
    apartment: {
      general:{

      }
    },

    photos:{},
    thumbnail:"https://res.cloudinary.com/my-room-abroad/image/upload/w_600/Logo/mra-no-photos-placeholder.jpg",
  }),
  computed:{
    ...mapGetters({
      user: types.GET_USER
    }),
    remainingRooms(){
      // console.log(this.apartment.hasOwnProperty('featureList'))
      if (this.apartment.hasOwnProperty('featureList')){
        if(this.apartment.hasOwnProperty('rooms')){
          let counter=0
          for (var room in this.apartment.rooms) {
            if (this.apartment.rooms.hasOwnProperty(room)) {
              ++counter
            }
          }
          return this.apartment.featureList.numberOfBedrooms - counter
        } else {
          return this.apartment.featureList.numberOfBedrooms
        }
        // console.log()
      } else {
        return 0
      }
    },
    orderedRooms:{
      get() {
        let t=[]
        // console.log('run get');
        if (this.apartment!=null){

          for (var room in this.apartment.rooms) {
            if (this.apartment.rooms.hasOwnProperty(room)) {
              // console.log(room);
              // // console.log('THE ID: , ', this.apartment.rooms[room]);
              // axios.get('/rSeen.json?orderBy="id"&equalTo="' + room + '"')
              // .then(res=>{
              //   res.data.roomId=res.data.id
              //
              //   this.$emit('addRoomCount',res.data)
              // })
              let k=this.apartment.rooms[room]
              k.id=room
              t.push(this.apartment.rooms[room])
            }
          }
          t.sort(function(a,b) {
              var x = a.number.toLowerCase();
              var y = b.number.toLowerCase();
              return x < y ? -1 : x > y ? 1 : 0;
          });
        }
        return t
      },
      set(val){
        // console.log('run set');
        // console.log(val);
        return val
      }
    }
  },

  methods: {
    del(){
      console.log('DELEte');
      axios.get('users/'+this.apartment.general.landlordId+'.json')
      .then(res=>{
        let ll=res.data
        console.log(ll.apartments,this.apartmentId);
        for (var id in ll.apartments) {
          if (ll.apartments.hasOwnProperty(id)) {
            if (ll.apartments[id].apartmentId==this.apartmentId){
              console.log('do Patch and del');
              axios.patch('apartments/'+this.apartmentId+'.json?auth='+this.$store.state.auth.idToken,{draft:true})
              axios.delete('users/'+this.apartment.general.landlordId+'/apartments/'+id+'.json?auth='+this.$store.state.auth.idToken)
            }
          }
        }

      })
      if (this.apartment.hasOwnProperty('rooms')){
        for (i in this.apartment.rooms){
          axios.patch('rooms/'+i+'.json?auth='+this.$store.state.auth.idToken,{draft:true})
        }
      }
    },

    duplicateFlat(){
      alert('flat is being duplicated.')
      console.log('duplicate this flat', this.apartment);
      let tFlat=JSON.parse(JSON.stringify(this.apartment))
      tFlat.general.location=null
      tFlat.draft=true
      tFlat.general.title=tFlat.general.title+ " - duplicate"
      tFlat.general.name=tFlat.general.name+ " - duplicate"
      tFlat.general.complete=false
      tFlat.general.dateAdded=new Date()
      tFlat.visual=null
      tFlat.rooms=null
      tFlat.visit=0
      console.log(tFlat);
      axios.post('/apartments.json' + '?auth=' + this.$store.state.auth.idToken, tFlat)
      .then((res) => {
        console.log('apttPosted', res);
        let aptId = res.data.name
        axios.post('/users/'+tFlat.general.landlordId+'/apartments.json' + '?auth=' + this.$store.state.auth.idToken, {
          'apartmentId':aptId
        })
        .then(()=>{
          console.log('Added to ll');
        })
        .catch(err=>{
          console.log(err);
        })
      })
      .catch(err=>{
        console.log(err);
      })
    },

  },
  created() {
    //do something after creating vue instance
    let vThis=this
    // console.log('DO CREATE', vThis.apartmentId);
    axios.get('apartments/' + vThis.apartmentId + '.json')
      .then(res => {
        // console.log('apt summary found')
        vThis.apartment = res.data
        // console.log('LOADED APARTMENT:!!!',vThis.apartment)
        if(vThis.apartment!=null && vThis.apartment.hasOwnProperty('visual')) {
          if (vThis.apartment.visual.photos.hasOwnProperty('0')){
          // console.log('set new thumbnail')
            vThis.thumbnail='https://res.cloudinary.com/my-room-abroad/image/fetch/h_200,q_auto,fl_lossy,f_auto/'+ vThis.apartment.visual.photos[0].url
            for (var room in this.apartment.rooms) {
              if (this.apartment.rooms.hasOwnProperty(room)) {
                // console.log(room);
                // console.log('THE ID: , ',room);
                axios.get('/rSeen.json?orderBy="id"&equalTo="' + room + '"')
                .then(res=>{
                  // res.data.roomId=res.data.id
                  // console.log(room, res.data);
                  this.$emit('addRoomCount',res.data)
                })
              }
            }
          }
        }
        // console.log('count remaining rooms')
        // vThis.countRemainingRooms()
      })
      .catch(err => {
        // console.log('apartments not found')
        console.log(err)
      })
  },
  components: {
    RoomInfo,
    Draggable
  }
}
</script>
<style lang="scss" scoped>
.apartmentCardContainer{

  .md-card{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
  }
  .photoBg{
    background: #e6e6e6;
  }

  .roomsList{
    padding: 5px 10px;
    .heading{
      border-bottom: 1px solid #bbb;
      margin-bottom: 20px;
    }
  }

}


.carousel {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    // width: 100%;
    height: 180px;
    overflow: hidden;
}
.card {
    margin: 5%;
    width: 90%;
}

.carouselImg {
    object-position: center;
    object-fit:cover;
    max-height: 180px;
}
.card-img-container {

    width: 100%;
    height: 180px;
    display: block;
    position: relative;
    overflow: hidden;
}
.card-img-container img {
    margin: auto;
    max-width: 100%;
    max-height: 180px;
    width: auto;
}

</style>
