<template>
  <v-flex xs12>

  <v-card class="apartmentCardContainer">
    <v-container grid-list-xs fluid>
      <v-layout  wrap>
        <v-flex xs12 md4>
          <v-img
          :src="thumbnail"
          height="200px"
          >
          </v-img>
        </v-flex>
        <v-flex xs12 md8>
          <v-card-title primary-title v-if="apartment.general.hasOwnProperty('name')">
            {{apartment.general.name}} - {{$t("newListing.wholeFlat")}}
          </v-card-title>
          <v-card-title primary-title v-else>
            {{$t('landlordDashboard.noTitle')}} -{{$t("newListing.wholeFlat")}}
          </v-card-title>
          <v-card-text>

            <RoomInfo :type="'Apartment'" :studioId="apartmentId" :apartmentId="apartmentId">
            </RoomInfo>
          </v-card-text>

        </v-flex>
        <v-card-actions>
            <v-btn color="primary" flat :href="'/apartment?apartmentId='+apartmentId">{{$t('landlordDashboard.manageApt')}}</v-btn>
            <v-btn color="#ff4000" dark v-if="user.roleValue>50"  flat @click="duplicateFlat()" >duplicate whole apartment</v-btn>
            <v-btn color="error" @click="del">Remove</v-btn>
        </v-card-actions>
      </v-layout>
    </v-container>
  </v-card>
</v-flex>
</template>
<script>
import axios from 'axios'
import RoomInfo from './RoomInfo.vue'
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'

export default {
  name: "",
  props: ['apartmentId'],
  data: () => ({
    thumbnail:"https://res.cloudinary.com/my-room-abroad/image/upload/w_600/Logo/mra-no-photos-placeholder.jpg",
    apartment: {general:{

    }},
  }),
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },
  methods: {
    del(){
      axios.get('users/'+this.apartment.general.landlordId+'.json')
      .then(res=>{
        let ll=res.data
        for (var id in ll.wholeApts) {
          if (ll.wholeApts.hasOwnProperty(id)) {
            if (ll.wholeApts[id].apartmentId==this.apartmentId){
              axios.patch('apartments/'+this.apartmentId+'.json?auth='+this.$store.state.auth.idToken,{draft:true})
              axios.delete('users/'+this.apartment.general.landlordId+'/wholeApts/'+id+'.json?auth='+this.$store.state.auth.idToken)
            }
          }
        }

      })
    },
    duplicateFlat(){
      alert('flat is being duplicated.')
      console.log('duplicate this flat', this.apartment);
      let tFlat=JSON.parse(JSON.stringify(this.apartment))
      tFlat.general.location=null
      tFlat.draft=true
      tFlat.general.title=null
      tFlat.general.dateAdded=new Date()
      tFlat.general.name=tFlat.general.name +" - Duplicate"
      tFlat.general.fullRoomNumber=null
      tFlat.general.complete=false
      tFlat.visual=null
      tFlat.tenants=null
      tFlat.rooms=null
      tFlat.visit=0
      tFlat.billing=null
      axios.post('/wholeApts.json' + '?auth=' + this.$store.state.auth.idToken, tFlat)
      .then((res) => {
        let aptId = res.data.name
        axios.post('/users/'+tFlat.general.landlordId+'/wholeApts.json' + '?auth=' + this.$store.state.auth.idToken, {
          'apartmentId':aptId
        })
        .catch(err=>{
          console.log(err);
        })
      })
      .catch(err=>{
        console.log(err);
      })
    }
  },
  created() {
    //do something after creating vue instance
    let vThis=this
    axios.get('wholeApts/' + vThis.apartmentId + '.json')
      .then(res => {
        // console.log('apt summary found')
        vThis.apartment = res.data
        // console.log('LOADED Apartment:!!!',vThis.apartment)
        if(vThis.apartment.hasOwnProperty('visual')) {
          if (vThis.apartment.visual.photos.hasOwnProperty('0')){
          // console.log('set new thumbnail')
          vThis.thumbnail='https://res.cloudinary.com/my-room-abroad/image/fetch/h_200,q_auto,fl_lossy,f_auto/'+ vThis.apartment.visual.photos[0].url
          }
        }
        // console.log('count remaining rooms')
        // vThis.countRemainingRooms()
      })
      .catch(err => {
        // ('apartments not found')
        console.log(err)
      })
  },
  components: {
    RoomInfo
  }
}
</script>
<style lang="scss" scoped>
.carousel {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    // width: 100%;
    height: 180px;
    overflow: hidden;
}
.card {
    margin: 5%;
    width: 90%;
}

.carouselImg {
    object-position: center;
    object-fit:cover;
    max-height: 180px;
}
.card-img-container {

    width: 100%;
    height: 180px;
    display: block;
    position: relative;
    overflow: hidden;
}
.card-img-container img {
    margin: auto;
    max-width: 100%;
    max-height: 180px;
    width: auto;
}

</style>
