<template>
  <v-layout  wrap align-center>
    <v-flex sm4 xs12 >

      <strong v-if="type=='Room'">{{$t('uploads.room')}} <span v-if="room.hasOwnProperty('general')">{{room.general.roomNumber}}</span></strong>
      <strong v-if="type=='Studio'">{{$t('uploads.studio')}} <span v-if="room.hasOwnProperty('general')">{{room.general.name}}</span></strong>
      <strong v-if="type=='Apartment'">{{$t('uploads.apartment')}} <span v-if="room.hasOwnProperty('general')">{{room.general.name}}</span></strong>
      <strong v-if="room.hasOwnProperty('billing')" > - <a :href="room.editUrl+'&edit=billing'">{{room.billing.price}} NT$</a></strong>
        <br>

      <small class="hidden-xs-only" v-if="room.hasOwnProperty('general') && room.general.hasOwnProperty('title')">{{room.general.title}} </small>
      <small class="hidden-xs-only" v-else>{{$t('landlordDashboard.unnamedRoom')}} </small>
    </v-flex >
    <v-flex xs12 sm4 v-if="roomId || studioId">
      <!-- {{ro/om.general.nextAvailability}} -->
   <v-menu
       :close-on-content-click="false"
       v-model="menu2"
       :nudge-right="40"
       lazy
       transition="scale-transition"
       min-width="290px"
     >
       <v-text-field
         slot="activator"
         v-model="newDate"
         :label="$t('generalInfo.nextAv')"
         prepend-icon="event"
         readonly
       ></v-text-field>
       <v-date-picker :locale="$vuetify.lang.current" v-model="newDate" @input="changeDate"></v-date-picker>
     </v-menu>
    </v-flex>
    <v-flex xs12 sm4 justify-space-between v-if="type =='Room'">
      <v-layout  wrap justify-end>
        <v-btn justify-end color="primary" :href="'/room/'+apartmentId+'?roomId='+roomId">{{$t('landlordDashboard.edit')}}</v-btn>

        <v-btn color="secondary" :href="'/details/'+roomId" target="_blank" v-if="!room.draft">{{$t('landlordDashboard.launch')}}</v-btn>
        <a color="primary" v-if="user.acceptSummer==true && room.summerBooked!=false" @click="summerAv(true)">Make available for summer</a>
        <a class="#ff4000" dark v-if="user.acceptSummer==true && room.summerBooked==false" @click="summerAv(false)">Make unavailable for summer</a>
        <a class="orangeText" v-if="user.roleValue>50 && remainingRooms>0" @click="duplicateRoom()">Duplicate Single Room</a>
      </v-layout>
      <AddTenant @addNewTenant="addNewTenant" :mid="originalDate" :moveOutDate="newDate" :myDialog="showTenantDialog" :noButton="true" @cancel="cancel"></AddTenant>
    </v-flex>
    <v-flex xs12 justify-space-between v-if="type =='Studio'">
      <v-layout  wrap>

        <v-btn justify-end color="primary" :href="'/studio?studioId='+ studioId">{{$t('landlordDashboard.edit')}}</v-btn>
        <br>
        <v-btn color="secondary" :href="'/studioDetails/'+studioId" v-if="!room.draft">{{$t('landlordDashboard.launch')}}</v-btn>
        <v-btn color="primary" v-if="user.acceptSummer==true && room.summerBooked!=false" @click="summerAv(true)">Make available for summer</v-btn>
        <v-btn color="#ff4000" dark v-if="user.acceptSummer==true && room.summerBooked==false" @click="summerAv(false)">Make unavailable for summer</v-btn>
      </v-layout>
      <AddTenant @addNewTenant="addNewTenant" :mid="originalDate" :moveOutDate="newDate" :myDialog="showTenantDialog" :noButton="true" @cancel="cancel"></AddTenant>
    </v-flex>
    <v-flex xs12 justify-space-between v-if="type =='Apartment'">
      <v-layout  wrap>

        <v-btn justify-end color="primary" :href="'/apartment?apartmentId='+ apartmentId">{{$t('landlordDashboard.edit')}}</v-btn>
        <br>
        <v-btn color="secondary" :href="'/apartmentDetails/'+apartmentId+'/apartment'" v-if="!room.draft">{{$t('landlordDashboard.launch')}}</v-btn>
        <v-btn color="primary" v-if="user.acceptSummer==true && room.summerBooked!=false" @click="summerAv(true)">Make available for summer</v-btn>
        <v-btn color="#ff4000" dark v-if="user.acceptSummer==true && room.summerBooked==false" @click="summerAv(false)">Make unAvailable for summer</v-btn>
      </v-layout>
      <AddTenant @addNewTenant="addNewTenant" :mid="originalDate" :moveOutDate="newDate" :myDialog="showTenantDialog" :noButton="true" @cancel="cancel"></AddTenant>
    </v-flex>
    <v-snackbar
      v-model="showSnackbar"
      color="success"
      :timeout="6000"
    >
      {{snackbarMessage}}
      <v-btn flat @click.native="showSnackbar = false">Close</v-btn>
    </v-snackbar>

  </v-layout>
</template>
<script>
import moment from 'moment'
import axios from 'axios'
import AddTenant from '../addListing/AddTenant.vue'
// import AWSSES from '../../aws-ses'
// AWSSES.emailing(userData, 'accountDeleted')
import {
  mapGetters
} from 'vuex'
import * as types from '../../store/types'

export default {
  name: "",
  props: ['roomId', 'apartmentId', 'studioId', 'type','remainingRooms'],
  data: () => ({
    menu2:false,
    showSnackbar:false,
    snackbarMessage:'',
    noRoom: false,
    room: {
      general:{
        nextAvailability:""
      }
    },
    showTenantDialog:false,
    dateChanged: false,
    originalDate: "",
    newDate: "",
    tenant: {},
    admin: {
      email: 'hello@myroomabroad.com',
      name: 'admin'
    },
  }),
  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),
  },

  methods: {
    // resetDate() {
    //   this.room.nextAvailability = this.originalDate
    // },
    duplicateRoom(){
      console.log('duplicate this room', this.room);
        let tFlat=JSON.parse(JSON.stringify(this.room))
        tFlat.draft=true
        tFlat.general.roomNumber=tFlat.general.roomNumber + " - Duplicate"
        tFlat.dateAdded=new Date()
        tFlat.general.fullRoomNumber=null
        tFlat.general.complete=false
        tFlat.visual=null
        tFlat.tenants=null
        tFlat.billing=null
        axios.post('/rooms.json' + '?auth=' + this.$store.state.auth.idToken, tFlat)
        .then((res) => {
          let roomId = res.data.name
          axios.patch('/apartments/'+tFlat.apartmentId+'/rooms/'+roomId+'.json' + '?auth=' + this.$store.state.auth.idToken, {
            number:tFlat.general.roomNumber
          })
          .then(()=>{
            this.$router.go()
          })
          .catch(err=>{
            console.log(err);
          })
        })
        .catch(err=>{
          console.log(err);
        })
    },
    changeDate(payload){
      // console.log(payload);
      // this.$refs.menu2.save(this.newDate)
      console.log('payload: ',payload, 'newDate: ',this.newDate);
      this.menu2=false
      console.log('date has been changed')
      this.showTenantDialog=true
    },
    summerAv(available){
      let baseUrl=""

      if (this.type=='Room'){
        baseUrl='rooms/'+ this.roomId
      }
      else if (this.type=='Studio') {
        baseUrl='studios/'+ this.studioId
      } else if (this.type=='Apartment') {
        baseUrl='wholeApts/'+ this.apartmentId
      }
      if (available==true){
        this.room.summerBooked=false
        this.room.septBooked=true
        axios.patch(baseUrl+'.json?auth='+this.$store.state.auth.idToken,{summerBooked:false, septBooked:true})
        .then(()=>{
          console.log('Patched av');
        })
      } else {
        this.room.summerBooked=true
        axios.patch(baseUrl+'.json?auth='+this.$store.state.auth.idToken,{summerBooked:true})
        .then(()=>{
          console.log('Patched Unav');
        })
      }
    },
    addNewTenant(payload) {

      console.log('payload', payload);
      if(this.room.tenants == undefined){
        this.room.tenants = {tenants:[]};
      }
      this.room.tenants['tenants'].push(payload.tenant)
      this.room.tenants['tenants']['complete'] = true;
      this.room.general.nextAvailability=payload.tenant.moveOutDate
      // this.newDate=moment(payload.tenant.moveOutDate).format('YYYY-MM-DD')
      console.log('new date:', this.newDate);
      this.updateListing()
    },
    updateListing() {
      let baseUrl=""
      if (this.type=='Room'){
        baseUrl='rooms/'+ this.roomId
      }
      else if (this.type=='Studio') {
        baseUrl='studios/'+ this.studioId
      } else if (this.type=='Apartment') {
        baseUrl='wholeApts/'+ this.apartmentId
      }
      // else {console.log('problem with THIS.TYPE')}
      // console.log('URL: ',baseUrl)
      axios.patch(baseUrl+'/tenants.json/?auth='+this.$store.state.auth.idToken,this.room.tenants)
      .then(()=>{
        // console.log('tenants updated');
      })
      .catch(err=>{
        console.log('error: ',err);
      })

      axios.patch(baseUrl+'/general.json/?auth='+this.$store.state.auth.idToken,this.room.general)
      .then(()=>{
        // console.log('date updated')
        // AWSSES.emailing(this.user, 'LLRoomUpdated')
        // AWSSES.emailing(this.admin, 'ARoomUpdated')
        this.snackbarMessage=this.$t("uploads.snackbarMessage")
        this.newDate=this.room.general.nextAvailability
        this.showSnackbar=true
        console.log('newDate: after update listing: ',this.newDate);
      })
      .catch(err=>{
        console.log('error: ',err);
      })
    },
    // addNewTenant(payload) {
    //   this.confirmNewDate()
    //   this.showTenantDialog = false
    //
    // },
    cancel(value) {
      // console.log('value : ' + value)
      if (!value && !this.tenant.moveInDate) {
        // console.log('canceling')
        this.newDate=moment(this.originalDate).format('YYYY-MM-DD')
      }

      this.showTenantDialog = false

    },
  },
  created() {
    if (this.type == "Room") {
      if (this.roomId) {
        // console.log('doing this')
        axios.get('rooms/' + this.roomId + '.json')
          .then(res => {
            // console.log('room found -->', res.data)
            this.room = res.data
            this.room.editUrl='/Room/'+this.apartmentId+"?roomId="+this.roomId
            if(res.data.general!=undefined){
              if (moment(this.room.general.nextAvailability).isValid()){
                this.originalDate = res.data.general.nextAvailability
                this.newDate=moment(this.room.general.nextAvailability).format('YYYY-MM-DD')
              } else {
                this.originalDate= moment().format('YYYY-MM-DD')
                this.newDate= moment().format('YYYY-MM-DD')
              }
            }
          })
          .catch(err=>{
            console.log('error: ',err);
          })
      }
    } else if (this.type == "Studio") {
      // console.log('inside the studiosummary')
      axios.get('studios/' + this.studioId + '.json')
        .then(res => {
          // console.log('studioSummary found')
          // console.log(res.data)
          this.room = res.data
          this.room.editUrl='/studio?studioId='+this.studioId
          if (moment(this.room.general.nextAvailability).isValid()){
            this.originalDate = res.data.general.nextAvailability
            this.newDate=moment(this.room.general.nextAvailability).format('YYYY-MM-DD')
          } else {
            this.originalDate= moment().format('YYYY-MM-DD')
            this.newDate= moment().format('YYYY-MM-DD')
          }
        })
        .catch(err => {
          console.log(err, 'error grabbing the room')
        })
      } else if (this.type == "Apartment") {
        // console.log('inside the studiosummary')
        axios.get('wholeApts/' + this.apartmentId + '.json')
          .then(res => {
            // console.log('aptSummary found')
            // console.log(res.data)
            this.room = res.data
            this.room.editUrl='/apartment?apartmentId='+this.apartmentId
            if (moment(this.room.general.nextAvailability).isValid()){
              this.originalDate = res.data.general.nextAvailability
              this.newDate=moment(this.room.general.nextAvailability).format('YYYY-MM-DD')
            } else {
              this.originalDate= moment().format('YYYY-MM-DD')
              this.newDate= moment().format('YYYY-MM-DD')
            }
          })
          .catch(err => {
            console.log(err, 'error grabbing the room')
          })
      }


  },
  components: {
    AddTenant,
  }
}
</script>
<style lang="scss" scoped>
.orangeText{
  color:#FF4000 !important;
}
.roomCardContainer{
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
  .roominfo {
      text-align: left;
      .md-button{
        margin: 0;
      }
  }

  .margin-auto {
      margin: auto;
  }

  .new-room {
      line-height: 2rem;
  }

  .hide-overflow {
      overflow: hidden;
  }

  .room-info-datepicker {
      padding-top: 0;
      min-height: 2rem;
      margin: 0;
      max-width: 120px;
  }

  .room-info-datepicker-input {
      height: 1.5em;
  }

  .more-spacing {
      line-height: 2rem;
  }

  .dashboard input {
      overflow: hidden;
  }

  .datepicker-trigger{
    width:100%;
  }

  .datepicker-trigger input{
    width:100%;
  }
}
</style>
